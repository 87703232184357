<template>
  <div class="container-404">
      <img src="assets/img/banner-3.png" alt="error-404" width="100%">
      <countdown class="redirect-message" :end-time="endTime" @finish="finishCountdown" v-if="isTimer">
        <template v-slot:process="time">
          <b-button class="btn">
            <a :href="redirectPath" class="btn-text">
              לחצו כאן למעבר לעמוד הדילים הראשי שלנו או המתינו "{{time.timeObj.s}}" שניות למעבר אוטומטי
            </a>
          </b-button>
        </template>
      </countdown>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue';

export default {
  name: 'not-found',
  components: {
    countdown: () => import('@/components/atoms/CountDown'),
    BButton,
  },
  data() {
    return {
      prevRoute: null,
      redirectPath: null,
      endTime: null,
      gotRedirectUrl: false,
    };
  },
  computed: {
    isTimer() {
      if (!this.endTime) return false;
      return true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async beforeMount() {
    if (this.$route.name !== '404') { await this.notify404(this.$route.path); }
  },
  async mounted() {
    document.querySelector('body').classList.add('flat-404');

    if (this.$route.name === '404') { await this.notify404(this.prevRoute?.path); }
  },
  created() {
    this.$store.commit('SET_TYPE_CHANNEL', '404-page');
  },
  destroyed() {
    document.querySelector('body').classList.remove('flat-404');
  },
  methods: {
    async notify404(urlNotFound) {
      this.redirectPath = await this.$store.dispatch('NOTIFY_ADMIN_404_ERROR', {
        urlPath: urlNotFound,
      });
      this.endTime = new Date().getTime() + 5000;
      if (this.redirectPath === null) this.redirectPath = '/';
      this.gotRedirectUrl = true;
    },
    finishCountdown() {
      this.goRedirect();
    },
    goRedirect() {
      if (!this.gotRedirectUrl) return;
      window.location = this.redirectPath;
    },
  },
};
</script>

<style scoped>
.container-404 img {
  width: 100%;
  height: calc(100vh - 110px);
}

.container-404 .btn {
  margin: 0 auto;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFA500;
}

.btn-text {
  font-weight: bold;
  line-height: 30px;
  color: black;
}

@media (max-width: 768px) {
  .container-404 img {
    width: 100%;
    height: auto
  }
}
</style>
